@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
}

#app-shell-header {
  background-color: white;
  height: 64px;
}

#app-shell-header img {
  padding: 13px 0;
  margin-left: 5%;
}

#app-shell-content {
  background-color: #00A4C6;
  min-height: 600px;
}

.blue, .blue * {
  color: #293D5E !important;
}

md-input-container.md-default-theme .md-input, md-input-container .md-input, md-input-container.md-default-theme .md-placeholder, md-input-container .md-placeholder, md-input-container.md-default-theme label, md-input-container label {
  color: #293D5E;
}

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on June 25, 2016 */
@font-face {
  font-family: 'Lato';
  src: url("/fonts/lato-light-webfont.woff2") format("woff2"), url("/fonts/lato-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("/fonts/lato-regular-webfont.woff2") format("woff2"), url("/fonts/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}



md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336;
}

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms;
}

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0;
}

[ui-view].ng-enter.ng-enter-active {
  opacity: 1;
}

.Page.ng-leave.ng-leave-active {
  display: none;
}

.u-center {
  text-align: center;
}

accessori-selector p {
  color: #293D5E !important;
}

accessori-selector md-list-item {
  background: white !important;
  color: #293D5E !important;
  border-bottom: 1px solid #000;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}

.md-thumb-container .md-sign {
  display: none !important;
}

img.disabled {
  opacity: 0.4;
}

md-progress-linear .md-bar {
  background-color: #ff3d00 !important;
  border-right: 5px solid black !important;
}

md-progress-linear .md-container {
  background-color: #333;
  border-radius: 2px;
}

.md-sidenav-left, .md-sidenav-right {
  z-index: 6000;
}

/* SideNAV Right */
.md-sidenav-left {
  background: white;
}

.md-sidenav-left md-toolbar {
  background: white;
  padding: 5%;
}

.md-sidenav-left md-toolbar > img {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  height: auto;
}

.md-sidenav-left md-content {
  background: white;
  color: #293D5E;
}

.md-sidenav-left md-list-item.disabled {
  opacity: 0.4;
}

/* SideNAV Right */
.md-sidenav-right {
  background: white;
}

.md-sidenav-right md-content {
  background: white;
}

/* */
.mv-margin-top {
  margin-top: 30px;
}

.mv-dettagli-icon {
  width: 90%;
  padding-top: 26%;
}

.dettagli_box_controls .mv-dettagli-icon {
  padding-top: 6%;
}

.mv-dettagli-play-icon {
  width: 75%;
  padding-top: 25%;
  padding-left: 3%;
}

.md-fab-action-item {
  text-align: center;
}

/* FIX */
md-fab-speed-dial md-fab-actions[aria-hidden] {
  height: 0px;
}

.Page-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
}

.Page-container > div {
  z-index: 2000;
}

/* FLASH Animation */
@keyframes bg-pulsate-1 {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  30% {
    background-color: white;
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes bg-pulsate-2 {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  30% {
    background-color: white;
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes bg-pulsate-3 {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  30% {
    background-color: white;
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

.Page-container.blink-1 {
  animation: bg-pulsate-1 500ms 1;
}

.Page-container.blink-2 {
  animation: bg-pulsate-2 500ms 1;
}

.Page-container.blink-3 {
  animation: bg-pulsate-3 500ms 1;
}

body {
  background: none !important;
}

.md-dialog-container {
  z-index: 7000 !important;
}

md-toast {
  z-index: 7000 !important;
}


bordature-selector p {
  color: #293D5E !important;
}

bordature-selector md-list-item {
  background: white !important;
  color: #293D5E !important;
  border-bottom: 1px solid #293D5E;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
}

bordature-selector md-checkbox .md-icon {
  border-color: white !important;
}

.confirmer {
  position: absolute;
  left: -43%;
  margin-top: 65px;
  width: 45%;
}


@import url(bower_components/angular-upload/src/directives/btnUpload.min.css);
/* or .min.css */
dettagli-selector p {
  color: #293D5E !important;
}

dettagli-selector md-list-item {
  background: #293D5E !important;
  color: white !important;
}

md-grid-tile.upload {
  border: 1px dashed white;
}

.submenu_icon {
  margin: 8px 15px;
  position: relative;
  text-align: center;
}

.submenu_icon md-icon {
  color: #FF6E40;
  position: absolute;
}

.dettagli_box .price {
  color: white;
  font-size: 1.6rem;
  margin-top: 8px;
  margin-bottom: 8px;
}

.dettagli_box.disabled {
  display: none;
}

.dettagli_box .disabled {
  opacity: 0.3;
}

img:focus, div:focus {
  outline: none;
  border: 0;
}

.dettagli_box_controls {
  padding: 5%;
  background-color: #FF6E40;
  border-radius: 20px;
  margin-top: -15px;
  margin-bottom: 4%;
  font-size: 0.8rem;
  color: #333 !important;
}

.dettagli_box_controls.mobile {
  padding: 0;
  background-color: #666;
  border-radius: 0px;
  margin-top: -15px;
  margin-bottom: 4%;
  font-size: 0.8rem;
  color: #333 !important;
}

.dettagli_box_control_selected {
  color: white;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #FF6E40;
}

font-selector {
  color: #293D5E !important;
}

font-selector md-list-item {
  background: #293D5E !important;
  color: white !important;
  border-bottom: 1px solid #000;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
}

#scroll-wrapper {
  overflow: hidden;
}

.height-100 {
  height: 100px !important;
}

.height-200 {
  height: 200px !important;
}

.height-full {
  height: 500px !important;
}

.height-300 {
  height: 300px !important;
}

.height-400 {
  height: 400px !important;
}

@font-face {
  font-family: 'cartoon';
  src: url("../../fonts/cartoon.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'classic';
  src: url("../../fonts/classic.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'corsivo';
  src: url("../../fonts/corsivo.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'custom';
  src: url("../../fonts/custom.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'fire';
  src: url("../../fonts/fire.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'graffiti';
  src: url("../../fonts/graffiti.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'horror';
  src: url("../../fonts/horror.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'retro';
  src: url("../../fonts/retro.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'runner';
  src: url("../../fonts/runner.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'teenager';
  src: url("../../fonts/teenager.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'teenager';
  src: url("../../fonts/teenager.ttf") format("truetype");
  font-weight: bold;
}

.ForgotPassword-input {
  margin-bottom: 0;
}

.LoginForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px;
}

.LoginForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}

logo-selector md-grid-tile {
  background: #424242 !important;
}

logo-selector md-grid-tile img {
  width: 70%;
  height: auto;
}

logo-selector md-checkbox .md-label {
  font-weight: bold;
  font-size: 0.8rem;
}

logo-selector .ipsum {
  font-size: 0.7rem;
  font-weight: normal;
}

.hint {
  margin-top: 5px;
  /* Copy styles from ng-messages */
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: grey;
}

.hint.ng-hide,
.hint.ng-enter,
.hint.ng-leave.ng-leave-active {
  bottom: 26px;
  opacity: 0;
}

.hint.ng-leave,
.hint.ng-enter.ng-enter-active {
  bottom: 7px;
  opacity: 1;
}

.fade {
  margin-top: 5px;
  /* Copy styles from ng-messages */
  font-size: 15px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: grey;
}

.fade.ng-hide,
.fade.ng-enter,
.fade.ng-leave.ng-leave-active {
  bottom: 26px;
  opacity: 0;
}

.fade.ng-leave,
.fade.ng-enter.ng-enter-active {
  bottom: 7px;
  opacity: 1;
}

order-summary p {
  color: #293D5E !important;
}

order-summary .md-disabled {
  background: #9E9E9E;
}

order-summary md-list-item {
  background: white !important;
  color: #293D5E !important;
  border-bottom: 1px solid #000;
  font-weight: bold;
  padding: 3px 16px;
}

order-summary md-list-item.action-item {
  background: none !important;
  border-bottom: none;
}

order-summary .md-button {
  min-width: 1%;
  border-radius: 0px;
}

order-summary .md-button .md-ripple-container {
  border-radius: 0px;
}

order-summary p.md-primary {
  color: #D3DE6A !important;
}

order-summary p.md-accent {
  color: #ff9100 !important;
}

.RegisterForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px;
}

.RegisterForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}

.ResetPassword-input {
  margin-bottom: 0;
}

taglie-selector {
  color: #293D5E !important;
}

taglie-selector md-list-item {
  background: white !important;
  color: #293D5E !important;
  border-bottom: 1px solid #000;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
}

#volumeSlider {
  margin-left: 1rem;
  min-width: 7rem;
}

#volumeSlider .md-track-container {
  background-color: white;
}





.Footer {
  text-align: center;
  background-color: #F6F9FA;
  border-top: 1px solid #E9F0F3;
  padding-top: 40px;
  padding-bottom: 0px;
  color: #888888;
  font-weight: 300;
}

.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
  color: #888888;
}

.Footer-link {
  text-decoration: none;
  color: #888888 !important;
  font-weight: 400;
}

.Footer-link:hover {
  text-decoration: underline;
}

.Footer-logo {
  width: 49px;
  height: 46px;
}

.ForgotPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.DemoHeader {
  background-color: white;
  color: #293D5E;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.DemoHeader.hcento {
  height: 100%;
}

.DemoHeader-container {
  height: 100%;
  /*padding-left: 1.0rem;*/
}

.DemoHeader-logo {
  width: 171px;
  height: 41px;
  padding-top: 13px;
}

.DemoHeader-link {
  text-decoration: none;
  color: #535362 !important;
  padding: 0 20px;
  display: inline-block;
  transition: 250ms background-color;
  line-height: 64px;
}

.DemoHeader-link:hover {
  background-color: #F6F9FA;
}

.DemoHeader-github {
  margin-top: 17px;
  margin-left: 10px;
}

.DemoHeader-spacer {
  padding-top: 56px;
}


body {
  font-family: 'Lato', sans-serif;
}

.Landing {
  height: 100%;
}

.Landing-cover {
  background-color: #00A4C6;
  background-image: url("/img/cover.jpg");
  background-size: cover;
  width: 100%;
  height: 700px;
}

.Landing-heading,
.Landing-subtitle {
  color: white;
}

.Landing-headingLight {
  font-weight: 300;
  letter-spacing: -3px;
}

.Landing-heading {
  color: white;
  margin-top: 20px;
  font-size: 60px;
  text-align: center;
  letter-spacing: -1px;
}

.Landing-subtitle {
  opacity: 0.7;
  text-align: center;
}

.Landing-getStarted {
  padding: 12px 30px;
  font-weight: bold;
  color: #535362 !important;
  border-radius: 4px;
  transition: box-shadow 250ms;
}

.Landing-getStarted:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
}

.Landing-laravelAngular {
  position: relative;
}

.Landing-ampersand {
  font-size: 50px;
  color: #00A4C6;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 300;
}

.Landing-angular {
  background-color: #F6F9FA;
  border-left: 1px solid #E9F0F3;
}

.Landing-laravel,
.Landing-angular {
  text-align: center;
  color: #21455B;
  border-bottom: 1px solid #E9F0F3;
}

.Landing-laravelAngular-title {
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #21455B;
}

.Landing-laravelAngular-subtitle {
  color: #535362;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300;
}

.DemoCode {
  font-family: "Lucida Console", Monaco, monospace;
  overflow: auto !important;
  padding: 10px 10px 10px 20px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
  margin: 30px auto 100px;
  text-align: left;
  font-size: 14px;
}

.DemoCode-highlight {
  color: #DD4A68;
}

.DemoCode-comment {
  color: #888888;
}

.DemoCode-operator {
  color: #888888;
}

.DemoCode-string {
  color: #690;
}

.DemoCode-secondary {
  color: #07A;
}

.Landing-features {
  text-align: center;
  margin: 60px 10px;
}

.Landing-featuresMainTitle {
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 15px;
  color: #21455B;
}

.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #535362;
  font-weight: 300;
}

.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px;
}

.Landing-featuresEntry-icon {
  height: 100%;
}

.Landing-featuresTitle {
  color: #21455B;
  letter-spacing: -1px;
  margin-top: 40px;
}

.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #535362;
}

.Landing-featuresEntry--restful {
  background-color: #00A4C6;
}

.Landing-featuresEntry--jwt {
  background-color: #EF704D;
}

.Landing-featuresEntry--generators {
  background-color: #25C0CC;
}

.Landing-featuresEntry--restful md-icon {
  width: 100px;
}

.Landing-featuresEntry--jwt md-icon {
  width: 80px;
}

.Landing-featuresEntry--generators md-icon {
  width: 80px;
}

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
}


.Register-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.ResetPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
}



/*# sourceMappingURL=app.css.map */
